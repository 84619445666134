<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container" v-if="areaDetail">
      <official-partner-area-header :areaDetail="areaDetail"></official-partner-area-header>
      <div class="search--container">
        <search-header></search-header>
        <div class="d-flex justify-space-between">
          <div class="left--col">
            <search-content></search-content>
          </div>
          <div class="right--col" :class="{ active: isActive }">
            <search-filters></search-filters>
          </div>
        </div>
      </div>
    </div>
    <script v-html="jsonldBreadcrumb" type="application/ld+json"></script>
    <script v-html="jsonld" type="application/ld+json"></script>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import SearchHeader from '@/components/search/header';
import SearchContent from '@/components/search/content';
import SearchFilters from '@/components/search/filters';
const OfficialPartnerAreaHeader = () => import('@/components/official-partner/header-area');

import DefaultHeadMixin from '@/mixins/defaultHead';
import HelperMixin from '@/mixins/helpers';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'search-page',
  mixins: [DefaultHeadMixin, HelperMixin],
  components: {
    DefaultLayout,
    SearchHeader,
    SearchContent,
    SearchFilters,
    OfficialPartnerAreaHeader,
  },
  computed: {
    ...mapState({
      isActive: (state) => state.v2.search.activeFilterSidebar,
      listingType: (state) => state.v2.search.listingType,
      areaDetail: (state) => state.v2.officialPartner.areaDetail,
      listings: (state) => state.v2.search.listings,
    }),
    ...mapGetters({
      activePropertyType: 'v2/search/activePropertyType',
    }),
    partnerName() {
      return this.areaDetail ? this.areaDetail.partner_name : '';
    },
    partnerUrl() {
      return this.areaDetail ? '/' + this.areaDetail.url.split('/')[0] : '';
    },
    areaName() {
      return this.areaDetail ? this.areaDetail.name : '';
    },
    breadcrumbs() {
      if (!this.areaDetail) return [];
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.partnerName,
          to: this.partnerUrl,
          exact: true,
        },
        {
          text: this.areaName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
    jsonldBreadcrumb() {
      let baseUrl = this.$store.state.global.baseUrl;
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: this.$t('breadcrumb.homepage'),
            item: baseUrl + '/',
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: this.partnerName,
            item: baseUrl + this.partnerUrl,
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: this.areaName,
            item: baseUrl + this.$router.currentRoute.path,
          },
        ],
      };
    },
    jsonld() {
      let baseUrl = this.$store.state.global.baseUrl;
      let path = this.$router.currentRoute.path;
      const name = this.areaDetail.name;
      const partnerName = this.partnerName;
      const address = this.areaDetail.address;

      const title = name + ' | Official Partner Area Rentfix';

      let description =
        name +
        ' oleh ' +
        partnerName +
        ' di ' +
        address +
        ' bekerja sama dengan Rentfix untuk membantu Anda melakukan proses sewa menyewa properti mulai dari ' +
        'rumah, apartemen, ruko, kantor, ' +
        'gudang, kios, booth, coworking space, sampai ruang pertemuan di seluruh Indonesia. | Rentfix';

      let itemList = [],
        i = 1;
      for (let listing of this.listings) {
        let newListing = listing;
        newListing.isActive = true;
        itemList.push({
          '@type': 'ListItem',
          position: i,
          item: this.getListingJsonld({
            baseUrl: baseUrl,
            path: listing.links.detail,
            listing: listing,
            galleries: listing.photos,
          }),
        });
        i++;
      }

      return {
        '@type': 'RealEstateListing',
        description: description,
        name: title,
        url: baseUrl + path,
        mainEntity: {
          '@type': 'ItemList',
          itemListElement: itemList,
        },
      };
    },
  },
  async fetch({ store, route, query, params, error }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    console.log(
      'Panggil get search listing dari asyncData di search/index.vue dengan isi route: ',
      route,
    );

    store.commit('global/SET_LOADING', true);

    await store.dispatch('v2/search/loadListingTypeFromRoute', {
      query: query,
      params: route.params,
    });
    await store.dispatch('v2/search/loadSearchTypeFromRoute', {
      query: query,
      params: route.params,
    });

    console.log('SEARCH TYPE: ', store.state.v2.search.searchType);

    store.commit('v2/search/set_listings', { data: [], meta: null });
    store.commit('v2/search/set_premier_listings', []);
    store.commit('v2/search/set_projects', { data: [], meta: null });
    store.commit('v2/search/SET_OFFICIAL_PARTNER_AREAS_DATA', []);
    store.commit('v2/search/SET_OFFICIAL_PARTNER_AREAS_META', null);

    let allPromises = [];
    const categoryId = parseInt(query.property_category);
    await store.dispatch('v2/search/setSelectedCategoryId', {
      payload: categoryId,
      query: query,
    });

    await store.dispatch('v2/search/loadListingTypeFromRoute', {
      query: query,
      params: route.params,
    });
    if (store.state.v2.search.listingType === 'R') {
      allPromises.push(store.dispatch('v2/masters/getPropertyTypesByCategory', categoryId));
      allPromises.push(store.dispatch('v2/masters/getRentTypesByCategory', categoryId));
      allPromises.push(store.dispatch('v2/masters/getRentPeriodsByCategory', categoryId));
    } else {
      allPromises.push(store.dispatch('v2/masters/getPropertyTypesByCategory', categoryId));
    }

    allPromises.push(
      store.dispatch('v2/officialPartner/getAreaDetail', {
        url: `${params.partnerUrl}/${params.areaUrl}`,
      }),
    );

    try {
      await Promise.all(allPromises);
    } catch (e) {
      error(new Error(e.message), 404);
    }

    let areaDetail = store.state.v2.officialPartner.areaDetail;
    if (!areaDetail) {
      error(new Error('Page does not exist!'), 404);
    }
    store.commit('v2/search/SET_LISTING_TYPE', areaDetail.listing_type);

    await store.dispatch('v2/search/getSearchListing', { query: query, params: route.params });
    store.commit('global/SET_LOADING', false);

    let propertyTypes = store.state.v2.masters.propertyTypes;
    let propertyTypeId = store.state.v2.search.propertyTypeId;
    if (propertyTypeId) {
      let propertyType = await propertyTypes.find(function (item) {
        return item.id === Number(propertyTypeId);
      });
      if (propertyType) {
        propertyType = JSON.parse(JSON.stringify(propertyType));
      } else {
        propertyType = '';
      }

      store.commit('v2/masters/set_property_type', propertyType);
    } else {
      store.commit('v2/masters/set_property_type', '');
    }

    const name = areaDetail.name;
    const partnerName = store.state.v2.officialPartner.areaDetail.partner_name;
    const baseUrl = store.state.global.baseUrl;
    const ogImage = areaDetail.partner_logo_photo;
    const address = areaDetail.address;

    const title = name + ' | Official Partner Area Rentfix';

    let description =
      'Rentfix.com - ' +
      name +
      ' oleh ' +
      partnerName +
      ' di ' +
      address +
      ' bekerja sama dengan Rentfix untuk membantu Anda melakukan proses sewa menyewa properti mulai dari ' +
      'rumah, apartemen, ruko, kantor, ' +
      'gudang, kios, booth, coworking space, sampai ruang pertemuan di seluruh Indonesia. | Rentfix';
    if (description.length > 310) description = description.substring(0, 307) + '...';
    description += ' | Rentfix';

    const head = {
      title: title,
      meta: [
        { hid: 'og-url', property: 'og:url', content: baseUrl + route.fullPath },
        { hid: 'og-type', property: 'og:type', content: 'article' },
        {
          hid: 'og-title',
          property: 'og:title',
          content: title,
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: description,
        },
        { hid: 'og-site-name', property: 'og:site_name', content: 'Rentfix' },
        {
          hid: 'og-image',
          property: 'og:image',
          content: ogImage,
        },
        { hid: 'og-image-width', property: 'og:image:width', content: '450' },
        { hid: 'og-image-height', property: 'og:image:height', content: '450' },
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    // IMPORTANT: Harus pakai fetch, pakai asyncData tidak jalan ganti head-nya.
    store.commit('head/SET_CONTENT', head);
  },
  async mounted() {
    await this.mountSearch();
  },
  methods: {
    async mountSearch() {
      if (this.$store.state.global.loggedIn) {
        this.$store.dispatch('v2/search/getShortlists');
      }
    },
  },
};
</script>
